








































































































































































import {
  defineComponent,
  ref,
  useContext,
  onMounted,
  useFetch,
} from "@nuxtjs/composition-api";
import LazyHydrate from "vue-lazy-hydration";
import { useCache, CacheTagPrefix } from "@vue-storefront/cache";
import { SfBanner, SfCallToAction,SfButton } from "@storefront-ui/vue";
import { CmsPage } from "~/modules/GraphQL/types";
import HeroSection from "~/components/HeroSection.vue";
import SaleSection from "~/components/SaleSection.vue";
import CustomBannerGrid from "~/components/CustomBannerGrid.vue";
import { getMetaInfo } from "~/helpers/getMetaInfo";
import { useContent } from "~/composables";
import LoadWhenVisible from "~/components/utils/LoadWhenVisible.vue";
import FreshArrivals from "~/components/FreshArrivals.vue";
import ThoughtfulGifts from "~/components/ThoughtfulGifts.vue";

export default defineComponent({
  name: "HomePage",
  components: {
    HeroSection,
    SaleSection,
    LazyHydrate,
    LoadWhenVisible,
    SfBanner,
    CustomBannerGrid,
    SfCallToAction,
    SfButton,
    MobileStoreBanner: () =>
      import(/* webpackPrefetch: true */ "~/components/MobileStoreBanner.vue"),
    NewProducts: () =>
      import(/* webpackPrefetch: true */ "~/components/NewProducts.vue"),
      FreshArrivals: () =>
      import(/* webpackPrefetch: true */ "~/components/FreshArrivals.vue"),
      ThoughtfulGifts: () =>
      import(/* webpackPrefetch: true */ "~/components/ThoughtfulGifts.vue"),
    Brands: () =>
      import(/* webpackPrefetch: true */ "~/components/Brands.vue"),
    Category: () =>
      import("~/components/Category.vue"),
    Newsletter: () =>
      import("~/components/Newsletter.vue"),
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const { addTags } = useCache();
    const { loadPage } = useContent();
    const { app } = useContext();
    const year = new Date().getFullYear();
    const { isDesktop } = app.$device;

    const page = ref<CmsPage | null>;
    const hero = ref({
      title: app.i18n.t("30-80% OFF"),
      subtitle: app.i18n.t("Sale is Live"),
      description: app.i18n.t("+ EXTRA 5% OFF"),
      buttonText: app.i18n.t("SHOP NOW"),
      imageSrc: "/homepage/banner.png",
      imageWidth: isDesktop ? 1600 : 328,
      imageHeight: isDesktop ? 578 : 224,
      imageConfig: {
        fit: "contain",
        format: "webp",
      },
      link: "/default/sale.html",
    });
    const sale = ref({
      title: app.i18n.t("30-80% OFF"),
      subtitle: app.i18n.t("Clearance Sale"),
      description: app.i18n.t("On Premium Collection of Glassware"),
      buttonText: app.i18n.t("SHOP NOW"),
      imageSrc: "/homepage/sale.png",
      imageWidth: isDesktop ? 1600 : 328,
      imageHeight: isDesktop ? 578 : 224,
      imageConfig: {
        fit: "contain",
        format: "webp",
      },
      link: "/default/sale.html",
    });
    const banners = ref([
      {
        slot: "banner-A",
        subtitle: app.i18n.t('Plate Specials'),
        title: app.i18n.t('30% OFF'),
        image: "/homepage/banner-left-top.png",
        imageConfig: {
          fit: "contain",
          width: isDesktop ? 312 : 328,
          height: isDesktop ? 310 : 343,
        },
        class: "sf-banner--slim banner__tshirt",
        link: "/women/women-clothing-shirts",
        description:"",
        buttonText:""
      },
      {
        slot: "banner-B",
        subtitle: app.i18n.t('Plate Specials'),
        title: app.i18n.t('30% OFF'),
        image: "/homepage/banner-left-bottom.png",
        imageConfig: {
          fit: "contain",
          width: isDesktop ? 312 : 328,
          height: isDesktop ? 310 : 343,
        },
        class: "sf-banner--slim banner__tshirt",
        link: "/women/women-shoes-sandals",
        description:"",
        buttonText:""
      },
      {
        slot: "banner-C",
        subtitle: app.i18n.t('Bundle Up & Save'),
        title: app.i18n.t('50% OFF'),
        image: "/homepage/banner-middle.png",
        imageConfig: {
          fit: "contain",
          width: isDesktop ? 648 : 328,
          height: isDesktop ? 644 : 343,
        },
        class: "sf-banner--slim banner-central desktop-only",
        link: "/women/women-clothing-dresses",
        description:"",
        buttonText:""
      },
      {
        slot: "banner-D",
        subtitle: app.i18n.t('Plate Specials'),
        title: app.i18n.t('30% OFF'),
        image: "/homepage/banner-right-top.png",
        imageConfig: {
          fit: "contain",
          width: isDesktop ? 312 : 328,
          height: isDesktop ? 310 : 343,
        },
        class: "sf-banner--slim banner__tshirt",
        link: "/women/women-clothing-shirts",
        description:"",
        buttonText:""
      },
      {
        slot: "banner-E",
        subtitle: app.i18n.t('Plate Specials'),
        title: app.i18n.t('30% OFF'),
        image: "/homepage/banner-right-bottom.png",
        imageConfig: {
          fit: "contain",
          width: isDesktop ? 312 : 328,
          height: isDesktop ? 310 : 343,
        },
        class: "sf-banner--slim banner__tshirt",
        link: "/women/women-shoes-sandals",
        description:"",
        buttonText:""
      },
    ]);
    const callToAction = ref({
      title: app.i18n.t("Stuck on what to choose for that special occasion? We've got you covered!  Explore our tailored suggestions guaranteed to delight your loved ones!"),
      description: '',
      buttonText: app.i18n.t('SHOP NOW'),
      background: '#F96C3B'
    });

    /*useFetch(async () => {
      page.value = await loadPage({ identifier: "home" });
    });*/

    onMounted(() => {
      addTags([{ prefix: CacheTagPrefix.View, value: "home" }]);
    });

    // @ts-ignore
    return {
      banners,
      callToAction,
      hero,
      sale,
      page,
    };
  },
  /*head() {
    return getMetaInfo(this.page);
  },*/
});
