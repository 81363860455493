import { render, staticRenderFns } from "./CarouselLeftArrow.vue?vue&type=template&id=2690fc0b&scoped=true&%23prev=prevArrow&"
import script from "./CarouselLeftArrow.vue?vue&type=script&lang=js&"
export * from "./CarouselLeftArrow.vue?vue&type=script&lang=js&"
import style0 from "./CarouselLeftArrow.vue?vue&type=style&index=0&id=2690fc0b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2690fc0b",
  null
  
)

export default component.exports