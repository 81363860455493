



















































import { defineComponent,PropType } from "@nuxtjs/composition-api";
import { ImageModifiers } from "@nuxt/image";
import { SfButton, SfImage } from "@storefront-ui/vue";
import { useFetch, ref } from '@nuxtjs/composition-api';
import { useContent } from '~/composables';
import CarouselLeftArrow from "./CarouselLeftArrow.vue";
import CarouselRightArrow from "./CarouselRightArrow.vue";
import RightArrowIcon from "./Icons/RightArrowIcon.vue";
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'


export default defineComponent({
  name: "HeroSection",
  components: {
    SfButton,
    SfImage,
    CarouselLeftArrow,
    CarouselRightArrow,
    RightArrowIcon,
    VueSlickCarousel
  },


  setup(props) {
    const { loadBlocks } = useContent();
    const blocks = ref([]);

    useFetch(async () => {
      if (props.identifiers) {
       // blocks.value = await loadBlocks({ identifiers: ['banner'] });
       // console.log(blocks.value[0]['identifier']);
      }
    });

    const carouselSettings = {
      "arrows": true, "dots": true, "infinite": true, "slidesToShow": 1, "autoplay": true,"speed": 1000,  "slidesToScroll": 1,"autoplaySpeed": 3500,"cssEase": "linear","pauseOnDotsHover": true,
  "pauseOnFocus": true,
  "pauseOnHover": true, "responsive": [
        {
          "breakpoint": 1366,
          "settings": {
              "lazyLoad": "ondemand",
            "slidesToShow": 1,
            "slidesToScroll": 1,
            "arrows": true,
            "dots": true,
            "infinite": true,
           

          }
          
        },
        {
          "breakpoint": 850,
          "settings": {
            "slidesToShow": 1,
            "slidesToScroll": 1,
            "arrows": true,
            "autoplay": true,

          }
        },
        {
          "breakpoint": 600,
          "settings": {
            "slidesToShow": 1,
            "slidesToScroll": 1,
            "arrows": true,
            "autoplay": true,

          }
        },
        {
          "breakpoint": 480,
          "settings": {
            "slidesToShow": 1,
            "slidesToScroll": 1,
            "arrows": true,
            "autoplay": true,

          }
        }
      ]
    };
    return {
      
      carouselSettings
    };
  },

  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    buttonText: {
      type: String,
      default: "",
    },
    imageHeight: {
      type: [Number, String],
      default: "",
    },
    imageSrc: {
      type: String,
      default: "",
    },
    imageTag: {
      type: String,
      default: "",
    },
    imageWidth: {
      type: [Number, String],
      default: "",
    },
    link: {
      type: String,
      default: "",
    },
    nuxtImgConfig: {
      type: Object as PropType<ImageModifiers | {}>,
      default: () => ({}),
    },
    identifiers: {
      type: Array as () => string[],
      default: () => []
    },
  },
});
